<template>
  <el-dialog width="390px" :visible.sync="applicationDialog" :show-close="false" class="dialog-blank" @close="hideDialog">
    <div class="application-dialog">
      <span>请先报名课程!</span>
      <div @click="hideDialog()">确定</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'application-dialog',
  props: {
    applicationDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog');
    }
  }
};
</script>

<style lang="scss">
.dialog-blank {
  display: flex;
  align-items: center;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .application-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0;
    span {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      color: #222222;
      margin-bottom: 43px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 40px;
      background: #2869F5;
      border-radius: 20px;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
