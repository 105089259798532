<template>
  <div class="package-page">
    <div v-if="!loading" class="content-wrap">
      <div class="basic-blank">
        <div class="cover">
          <img v-if="packageInfo.pc_cover1" :src="packageInfo.pc_cover1 ? packageInfo.pc_cover1 : packageInfo.front_cover3" />
          <default-cover v-else />
        </div>
        <div class="basic-info">
          <div class="info-wrap">
            <div class="title">{{ packageInfo.name }}</div>
            <div class="tag">
              <span v-for="val in packageInfo.tag" :key="val.id">{{ val.name }}</span>
            </div>
            <div class="price-wrap">
              <template v-if="packageInfo.is_free === 0">
                <div class="sold-price">
                  <template v-if="isActivity">
                    <span v-if="parseFloat(packageInfo.activity_price) > 0">￥</span>{{ parseFloat(packageInfo.activity_price) > 0 ? packageInfo.activity_price : '免费' }}
                  </template>
                  <template v-else>
                    <span>￥</span>{{ packageInfo.price }}
                  </template>
                </div>
                <div v-if="isActivity" class="origin-price">￥{{ packageInfo.price }}</div>
              </template>
              <div v-else class="sold-price">免费</div>
              <div class="sold-count">{{ packageInfo.virtually_pay_num !== '0' && packageInfo.virtually_pay_num !== '' ? packageInfo.virtually_pay_num : packageInfo.package_user1_count }}人已购买</div>
            </div>
            <div v-if="isActivity && countDownContent" class="time-wrap">
              <img src="../assets/img/notice_time_icon.png" />
              <span>优惠倒计时  {{ countDownContent }} 后结束</span>
            </div>
          </div>
          <div v-if="packageInfo.package_user1 && (packageInfo.package_user1.length === 0 || packageInfo.package_user1 === null)" class="join-btn" @click="toBuy()" >
            <template v-if="packageInfo.is_free === 0">
              <template v-if="isActivity">
                {{ parseFloat(packageInfo.activity_price) > 0 ? '立即购买' : '立即报名'}}
              </template>
              <template v-else>立即购买</template>
            </template>
            <template v-else>立即报名</template>
          </div>
          <div v-else class="join-btn join-btn-disabled">已报名</div>
        </div>
      </div>
      <div class="detail-blank">
        <div class="nav-wrap">
          <span :class="{'nav-selected' : navIndex === 0}" @click="tapNav(0)">简介</span>
          <span :class="{'nav-selected' : navIndex === 1}" @click="tapNav(1)">包含课程</span>
          <span :class="{'nav-selected' : navIndex === 2}" @click="tapNav(2)">赠课&礼品包</span>
          <span :class="{'nav-selected' : navIndex === 3}" @click="tapNav(3)">评论({{ commentTotal }})</span>
        </div>
        <div class="detail-info">
          <introduce :introduction="packageInfo.introduction" v-if="navIndex === 0" />
          <detail-course :course="packageInfo.course" v-if="navIndex === 1" />
          <detail-course :course="packageInfo.gift" v-if="navIndex === 2" />
          <detail-comment :is-buy="packageIsBuy()" :target-id="$route.query.id" :target-type="'package'" v-if="navIndex === 3" />
        </div>
      </div>
    </div>
    <application-dialog :application-dialog="applicationDialog" @hideDialog="hideApplicationDialog" />
    <download-dialog :download-dialog="downloadDialog" @hideDialog="hideDownloadDialog" />
    <loading :is-load="loading" />
  </div>
</template>

<script>
import Introduce from '@/views/modules/introduce';
import detailComment from '@/views/modules/detail-comment';
import detailCourse from '@/views/modules/detail-course';
import * as courseService from '@/service/course-service';
import applicationDialog from '@/views/modules/application-dialog';
import downloadDialog from '@/views/modules/download-dialog';
import * as Utils from '@/utils';
import { Message } from 'element-ui';
export default {
  name: 'packageDetail',
  components: {
    Introduce,
    detailComment,
    detailCourse,
    applicationDialog,
    downloadDialog
  },
  data() {
    return {
      loading: false,
      navIndex: 0,
      applicationDialog: false,
      downloadDialog: false,
      packageInfo: {}, // 套课详情
      commentTotal: 0, // 评论数量
      isActivity: false, // 是否活动
      timeInterval: null,
      countDownContent: '' // 活动剩余时间
    };
  },
  mounted() {
    this.getPackageInfo();
    this.getCourseCommentList();
  },
  methods: {
    getPackageInfo() {
      const data = {
        id: this.$route.query.id
      };
      this.loading = true;
      courseService.getPackageInfo(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.packageInfo = res.data;
          this.isActivity = this.formatDate();
          if (this.isActivity) {
            this.countDown();
          }
        }
      });
    },
    timeFormat(param) {
      return param < 10 ? '0' + param : param;
    },
    /**
     * 活动倒计时
     * */
    countDown() {
      this.timeInterval = setInterval(() => {
        // 获取当前时间，同时得到活动结束时间数组
        const newTime = new Date().getTime();
        // 对结束时间进行处理渲染到页面
        const endTime = new Date(this.packageInfo.activity_expire_time).getTime();
        let obj = null;
        // 如果活动未结束，对时间进行处理
        if (endTime - newTime > 0) {
          const time = (endTime - newTime) / 1000;
          const day = Math.floor(time / 60 / 60 / 24);
          if (day > 3) {
            // 获取时、分、秒
            const hou = parseInt(time / 3600 % 24);
            const min = parseInt(time / 60 % 60);
            const sec = parseInt(time % 60);
            obj = {
              day: this.timeFormat(day),
              hou: this.timeFormat(hou),
              min: this.timeFormat(min),
              sec: this.timeFormat(sec)
            };
            this.countDownContent = `${obj.day}天`;
          }
          else {
            // 获取时、分、秒
            const hou = parseInt(time / 3600);
            const min = parseInt(time % 3600 / 60);
            const sec = parseInt(time % 3600 % 60);
            obj = {
              hou: this.timeFormat(hou),
              min: this.timeFormat(min),
              sec: this.timeFormat(sec)
            };
            this.countDownContent = `${obj.hou}:${obj.min}:${obj.sec}`;
          }
        }
        else { // 活动已结束
          clearInterval(this.timeInterval);
        }
      }, 1000);
    },
    /**
     * 活动是否有效
     * */
    formatDate() {
      const date = new Date();
      const activityDate = new Date(this.packageInfo.activity_expire_time);
      if (activityDate > date) {
        return true;
      }
      return false;
    },
    /**
     * 获取评论数量
     * */
    getCourseCommentList() {
      const data = {
        target_id: this.$route.query.id,
        target_type: 'package',
        is_my: '-1'
      };
      courseService.getCommentList(data).then(res => {
        if (res.code === 1) {
          this.commentTotal = res.data.total;
        }
      });
    },
    tapNav(index) {
      this.navIndex = index;
    },
    /**
     * 立即报名/立即购买
     * */
    toBuy() {
      if (Utils.getLoginCondition()) {
        // 立即购买
        if (this.packageInfo.is_free === 0) {
          if (this.isActivity) {
            if (parseFloat(this.packageInfo.activity_price) > 0) {
              this.downloadDialog = true;
            }
            else {
              this.getFreePackage();
            }
          }
          else {
            this.downloadDialog = true;
          }
        }
        // 立即报名
        else {
          this.getFreePackage();
        }
      }
      else {
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    /**
     * 领取免费套课
     * */
    getFreePackage() {
      const data = {
        package_id: this.$route.query.id
      };
      courseService.getFreePackage(data).then(res => {
        if (res.code === 1) {
          Message({
            message: '报名成功',
            type: 'success',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
          this.getPackageInfo();
        }
        else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      });
    },
    /**
     * 隐藏报名弹窗
     * */
    hideApplicationDialog() {
      this.applicationDialog = false;
      this.downloadDialog = true;
    },
    /**
     * 隐藏app弹窗
     * */
    hideDownloadDialog() {
      this.downloadDialog = false;
    },
    packageIsBuy() {
      if (this.packageInfo.package_user1 && this.packageInfo.package_user1.length > 0) {
        return 1;
      }
      return 0;
    }
  },
  destroyed() {
    clearInterval(this.timeInterval);
    this.timeInterval = null;
  }
};
</script>

<style lang="scss" scoped>
.package-page {
  line-height: 1;
  .content-wrap {
    display: flex;
    flex-direction: column;
    padding: 20px 0 46px;
    .basic-blank {
      display: flex;
      height: 315px;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 20px 25px;
      margin-bottom: 20px;
      .cover {
        width: 440px;
        height: 271px;
        margin-right: 25px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .basic-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .info-wrap {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #222222;
            margin-bottom: 17px;
          }
          .info {
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            margin-bottom: 17px;
          }
          .tag {
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            margin-bottom: 35px;
            span {
              margin-right: 12px;
              padding-right: 12px;
              border-right: 1px solid #555555;
            }
            span:last-child {
              border-right: none;
            }
          }
          .price-wrap {
            display: flex;
            align-items: flex-end;
            .sold-price {
              font-size: 24px;
              color: #E22323;
              span {
                font-size: 18px;
                font-weight: bold;
              }
            }
            .origin-price {
              font-size: 16px;
              font-weight: 500;
              text-decoration: line-through;
              color: #999999;
              margin-left: 12px;
            }
            .sold-count {
              font-size: 16px;
              color: #999999;
              margin-left: 56px;
            }
          }
          .time-wrap {
            display: flex;
            align-items: center;
            margin-top: 20px;
            img {
              width: 19px;
              height: 17px;
              margin-right: 7px;
            }
            span {
              font-size: 16px;
              color: #E22323;
            }
          }
        }
        .join-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 11px;
          width: 160px;
          height: 40px;
          background: #2869F5;
          border-radius: 20px;
          font-size: 18px;
          color: #FFFFFF;
          cursor: pointer;
        }
        .join-btn-disabled {
          background: #77777D;
          cursor: default;
        }
      }
    }
    .detail-blank {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border-radius: 8px;
      .nav-wrap {
        display: flex;
        padding: 22px 40px 20px;
        border-bottom: 1px solid #F6F6F6;
        span {
          padding-bottom: 11px;
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          margin-right: 72px;
          cursor: pointer;
        }
        .nav-selected {
          position: relative;
        }
        .nav-selected:after {
          position: absolute;
          content: '';
          left: calc( 50% - 16px );
          bottom: 0;
          width: 32px;
          height: 4px;
          background: #2869F5;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
