<template>
  <el-dialog width="580px" :visible.sync="downloadDialog" :show-close="false" class="download-dialod-blank" @close="hideDialog">
    <div class="download-dialog">
      <div class="close">
        <img @click="hideDialog" src="../../assets/img/close_icon.png" />
      </div>
      <div class="title">请扫码下载APP，在APP上操作!</div>
      <div class="code">
        <img :src="configInfo.download_image" />
      </div>
      <span>打开手机扫一扫</span>
      <div class="btn" @click="hideDialog">确定</div>
    </div>
  </el-dialog>
</template>

<script>
import * as Utils from '@/utils';
export default {
  name: 'download-dialog',
  props: {
    downloadDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      configInfo: {} // 配置信息
    };
  },
  mounted() {
    this.configInfo = Utils.getLocalStorage('config_info');
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog');
    }
  }
};
</script>

<style lang="scss">
.download-dialod-blank {
  display: flex;
  align-items: center;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .download-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 360px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 20px;
    .close {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 4px;
      img {
        width: 17px;
        height: 17px;
        cursor: pointer;
      }
    }
    .title {
      font-size: 16px;
      color: #222222;
      margin-bottom: 25px;
    }
    .code {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 140px;
      background: #FFFFFF;
      margin-bottom: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      margin-bottom: 31px;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 40px;
      background: #2869F5;
      border-radius: 20px;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
