<template>
  <div class="detail-course-blank">
    <template v-if="course.length > 0">
      <div class="course-item" v-for="val in course" :key="val.id">
        <div class="cover">
          <img v-if="val.pc_cover1" :src="val.pc_cover1" />
          <default-cover v-else />
        </div>
        <div class="info">
          <div class="title">{{ val.name }}</div>
          <div class="count">{{ val.virtually_pay_num !== '0' && val.virtually_pay_num !== '' ? val.virtually_pay_num : val.course_user_count }}已报名</div>
          <div class="price">{{ val.is_free === 0 ? val.price : '免费' }}</div>
        </div>
      </div>
    </template>
    <div v-else class="course-empty">
      <empty-data />
    </div>
  </div>
</template>

<script>
import emptyData from '@/views/modules/empty-data';
export default {
  name: 'detail-course',
  components: {
    emptyData
  },
  props: {
    course: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    /**
     * 跳转详情
     * course_type 1=普通课程 2=直播课 0=套课
     * */
    toNavInfo(info) {
      const path = info.course_type === '1' ? '/courseDetail' : info.course_type === '2' ? 'liveDetail' : '/packageDetail';
      this.$router.push({
        path: path,
        query: {
          id: info.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-course-blank {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px 23px;
  .course-item {
    display: flex;
    align-items: center;
    width: 560px;
    height: 145px;
    padding: 17px 20px 16px;
    background: #F8F9FB;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-right: 20px;
    .cover {
      width: 180px;
      height: 111px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      .title {
        font-size: 18px;
        color: #333333;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .count {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-bottom: 29px;
      }
      .price {
        font-size: 20px;
        color: #E22323;
      }
    }
  }
  .course-item:nth-child(2n) {
    margin-right: 0;
  }
  .course-empty {
    width: 100%;
  }
}
</style>
